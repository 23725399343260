import * as React from "react";
import SEO from "../components/SEO";
import styled from "styled-components";
import "@fontsource/spartan";

const Wrapper = styled.main`
  /* background-image: url("https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/background.jpg"); */
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin: 0;
  background: black;
`;

const Text = styled.div`
  max-width: min(1400px, 90%);
  font-size: 2.2rem;
  color: white;
  margin: 0 auto;
  padding-top: 25vh;
`;

// markup
const NotFoundPage = () => {
  return (
    <Wrapper>
      <SEO />
      {/* <Text>404 | PAGE NOT FOUND</Text> */}
    </Wrapper>
  );
};

export default NotFoundPage;
